/**
 * Returns the theme color assigned to a project's status.
 *
 * @param { string } status The project's status.
 * @returns { string } The theme color.
 */
export const projectStatusColor = (status) => {
  switch (status) {
    case "TS Not Started":
      return "secondary";

    case "TS In Progress":
      return "warning";

    case "TS Submitted":
      return "warning";

    case "TS Rejected":
      return "error";

    case "TS Reviewed":
      return "info";

    case "TS Accepted":
      return "success";

    case "TS Ok'd for Distro":
      return "success";

    case "NTP":
      return "error";

    case "Active Construction":
      return "warning";

    case "On Hold - Management":
      return "default";

    case "On Hold - Construction":
      return "default";

    case "Substantially Complete":
      return "warning";

    case "Complete":
      return "success";

    case "Closed Out":
      return "info";

    default:
      return "default";
  }
};

/**
 * Returns the theme color assigned to a contract's status.
 *
 * @param { string } status The contract's status.
 * @returns { string } The theme color.
 */
export const contractStatusColor = (status) => {
  switch (status) {
    case "Active":
      return "success";

    case "Closed":
      return "default";

    default:
      return "default";
  }
};

/**
 * Returns the theme color assigned to a construction record's (permit) status.
 *
 * @param { string } status The permit's status.
 * @returns { string } The theme color.
 */
export const permitStatusColor = (status) => {
  switch (status) {
    case "TS Not Started":
      return "default";

    case "TS In Progress":
      return "warning";

    case "TS Complete":
      return "error";

    case "Active Construction":
      return "warning";

    case "Checklist Complete":
      return "secondary";

    case "ADA Accepted":
      return "success";

    case "ADA Failed":
      return "error";

    case "Complete":
      return "info";

    default:
      return "default";
  }
};

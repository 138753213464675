<script>
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import { useContractStatuses, useContractTypes } from "../useContract";

  export default defineComponent({
    props: {
      isContractEditDialogOpen: {
        type: Boolean,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      editItem: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationProvider,
      ValidationObserver
    },
    setup(props, { emit }) {
      const { contractStatuses } = useContractStatuses();
      const { contractTypes } = useContractTypes();
      const form = ref(null);
      const overlay = ref(false);

      // form submit
      const onSubmit = () => {
        overlay.value = true;
        setTimeout(() => {
          emit("save", props.editItem);
          overlay.value = false;
        }, 3000);
        form.value.reset();
      };

      // form cancel
      const onCancel = () => {
        form.value.reset();
        emit("close-dialog", false);
      };

      return {
        contractStatuses,
        contractTypes,
        form,
        overlay,
        onSubmit,
        onCancel
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <!-- dialog -->
    <v-dialog :value="isContractEditDialogOpen" max-width="650px" persistent>
      <v-card>
        <v-card-title> Edit Contract </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <form @submit.prevent="onSubmit" @reset.prevent="reset">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contract Number"
                    :rules="{ required: true, regexUppercase: /^[A-Z\d]+$/ }"
                  >
                    <label
                      for="contractNum"
                      class="label-color font-weight-semibold d-block"
                    >
                      Contract Number
                    </label>
                    <v-text-field
                      id="contractNum"
                      v-model="editItem.contract_num"
                      placeholder="T201901012"
                      dense
                      outlined
                      clearable
                      required
                      @input="$emit('update:modelValue', $event)"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="FAP Number"
                    rules="alpha_num"
                  >
                    <label
                      for="fapNumber"
                      class="label-color font-weight-semibold d-block"
                    >
                      FAP Number
                    </label>
                    <v-text-field
                      id="fapNumber"
                      v-model="editItem.fap_num"
                      dense
                      outlined
                      clearable
                      required
                      @input="$emit('update:modelValue', $event)"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contract Type"
                    rules="required"
                  >
                    <label
                      for="contractType"
                      class="label-color font-weight-semibold d-block"
                    >
                      Contract Type
                    </label>
                    <v-select
                      id="contractType"
                      v-model="editItem.contract_type"
                      dense
                      outlined
                      clearable
                      required
                      :items="contractTypes"
                      item-value="display_type"
                      item-text="display_type"
                      @input="$emit('update:modelValue', $event)"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contract Status"
                    rules="required"
                  >
                    <label
                      for="contractStatus"
                      class="label-color font-weight-semibold d-block"
                    >
                      Contract Status
                    </label>
                    <v-select
                      id="contractStatus"
                      v-model="editItem.status"
                      dense
                      outlined
                      clearable
                      required
                      :items="contractStatuses"
                      item-value="display_status"
                      item-text="display_status"
                      @input="$emit('update:modelValue', $event)"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contract Description"
                    rules="max:5000"
                  >
                    <label
                      for="contractDescription"
                      class="label-color font-weight-semibold d-block"
                    >
                      Contract Description
                    </label>
                    <v-textarea
                      id="contractDescription"
                      v-model="editItem.contract_desc"
                      dense
                      outlined
                      clearable
                      required
                      auto-grow
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="reset" color="secondary" @click="onCancel">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="invalid"
            @click="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>
